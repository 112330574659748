import adminUserStrings from './AdminUsersView';

export default {
  ...adminUserStrings,
  createOrder: 'Create Order',
  customerInformation: 'Customer Information',
  customer: 'Customer',
  vehicleInformation: 'Vehicle Information',
  customerVehicleInformation: 'Customer Vehicle Information',
  replacementVehicleInformation: 'Replacement Vehicle Information',
  vehicle: 'Vehicle',
  pickupLocation: 'Pickup Location',
  pickup: 'Pickup',
  dropOffLocation: 'Drop Off Location',
  vehicleReturnLocation: 'Vehicle Return Location',
  dropOff: 'Dropoff',
  locations: 'Locations',
  reviewTowOrder: 'Review Tow Order',
  reviewImpoundOrder: 'Review Impound Order',
  reviewRepoOrder: 'Review Repo Order',
  notReviewed: 'Not Reviewed',
  submitTowOrder: 'Submit Tow Order',
  submitImpoundOrder: 'Submit Impound Order',
  submitRepoOrder: 'Submit Repo Order',
  tradeIn: 'trade in',
  incorrectAgentsSelected: 'Incorrect Agents Selected',
  selectOnlyOneAgent: 'Please select only one agent',
  valueIsRequired: 'Value is required',

  /** Customer Information step */
  pickupDateTimeRequested: 'Pickup Date / Time Requested',
  serviceIsRequired: 'Service is required',
  selectedService: 'Selected Service',
  gatePass: 'Gate Pass',
  phoneNumber: 'Phone Number',
  lotNumber: 'Lot Number',
  loadNumber: 'Load Number',
  auctionHouseInfo: 'Auction House Information',
  auctionHouseContactInformation: 'Auction House Contact Information',
  auctionHouseFirstNameIsRequired:
    'Auction house contact first name is required',
  auctionHouseLastNameIsRequired: 'Auction house contact last name is required',
  auctionHouseMobilePhoneIsRequired:
    'Auction house contact mobile phone is required',
  lotNumberIsRequired: 'Lot # is required',
  loadNumberIsRequired: 'Load # is required',
  readyLogisticsContactInfo: 'Ready Logistics Contact Information',
  readyLogisticsEscalationEmailIsRequired:
    'Ready logistics escalation phone number is required',
  readyLogisticsEscalationEmailMustBeValid:
    'Ready logistics escalation email must be a valid email',
  readyLogisticsEscalationFirstNameIsRequired:
    'Ready logistics escalation first name is required',
  readyLogisticsEscalationLastNameIsRequired:
    'Ready logistics escalation last name is required',
  readyLogisticsEscalationPhoneNumberIsRequired:
    'Ready logistics escalation phone number is required',
  customerContactInformation: 'Customer Contact Information',
  customerEmailMustBeValid: 'Customer email must be a valid email',
  customerFirstNameIsRequired: 'Customer first name is required',
  customerLastNameIsRequired: 'Customer last name is required',
  customerPhoneNumberIsRequired: 'Customer phone number is required',
  specialInstructions: 'Special Instructions',
  turoSpecialInstructions:
    'Transport scheduled or pickup ASAP? Any special instruction to the provider?',
  thisFieldIsRequired: 'This field is required',
  languagePreference: 'Language Preference',
  customerPreferredLanguageIsRequired: 'Language preference is required',
  dispatchOrderType: 'Dispatch order type',
  dispatchOrderTypeIsRequired: 'Dispatch order type is required',
  primaryContact: 'Primary Contact',
  primaryContactIsRequired: 'Primary contact is required',
  referenceNumber: 'Reference Number',
  reservationId: 'Reservation ID',
  reservationIdIsRequired: 'Reservation ID is required',
  guestInformation: 'Guest Information',
  guestFirstNameIsRequired: 'Guest first name is required',
  guestLastNameIsRequired: 'Guest last name is required',
  guestPhoneNumberIsRequired: 'Guest phone number is required',
  driverId: 'Driver ID',
  driverIdIsRequired: 'Driver ID is required',
  hostInformation: 'Host Information',
  hostEmailMustBeValid: 'Host email must be a valid email',
  hostFirstNameIsRequired: 'Host first name is required',
  hostLastNameIsRequired: 'Host last name is required',
  hostPhoneNumberIsRequired: 'Host phone number is required',
  turoInformation: 'Turo Contact',
  turoEmailMustBeValid: 'Turo email must be a valid email',
  turoFirstNameIsRequired: 'Turo first name is required',
  turoLastNameIsRequired: 'Turo last name is required',
  turoPhoneNumberIsRequired: 'Turo phone number is required',
  fileDropzone: 'Upload required paperwork needed for this transport',
  impoundSpecialInstructions: 'Impound Reason & Special Instructions',
  repoSpecialInstructions: 'Repo Reason & Special Instructions',
  callerInformation: 'Caller Information',
  callerFirstNameIsRequired: 'Caller first name is required',
  callerLastNameIsRequired: 'Caller last name is required',
  callerPhoneNumberIsRequired: 'Caller phone number is required',
  impoundLotInformation: 'Impound Contact Information',
  repoLotInformation: 'Repo Contact Information',
  turoImpoundContactInformation: 'Impound Contact Information (Turo agent)',
  turoRepoContactInformation: 'Repo Contact Information (Turo agent)',
  invalidPhoneNumber: 'Invalid Phone Number',
  pickUpLocationContact: 'Pickup Location Contact',
  pickUpLocationFirstNameIsRequired:
    'Pickup Location contact first name is required',
  pickUpLocationLastNameIsRequired:
    'Pickup Location contact last name is required',
  pickUpLocationMobilePhoneIsRequired:
    'Pickup Location contact mobile phone is required',
  dropOffLocationContact: 'Drop off Location Contact',
  dropOffLocationFirstNameIsRequired:
    'Drop off Location contact first name is required',
  dropOffLocationLastNameIsRequired:
    'Drop off Location contact last name is required',
  dropOffLocationMobilePhoneIsRequired:
    'Drop off Location contact mobile phone is required',
  genericMustBeValidEmail: 'Must be a valid E-mail address',
  orderType: 'Order Type',
  selectedOrderType: 'Selected Order Type',
  orderTypeRequired: 'Order Type is Required',
  department: 'Department',
  selectDepartment: 'Select department',
  departmentRequired: 'Department is Required',
  charactersLimitStart: 'Maximum of',
  charactersLimitEnd: 'characters exceeded',
  charactersNotExactLength: 'Field must be exactly',
  characters: 'characters',
  /**Customer in safe location dialogs */
  pleaseReadToCustomer: 'Please read this to the Customer',
  personalSafety: 'Your personal safety, that of your passengers, and other drivers is important.',
  preferToNotifyAuthorities: 'Would you prefer to notify the local authorities to ensure your safety before we continue with dispatching this service?',
  sureYouWantToContinue: 'Are you sure you want to continue with this dispatch?',
  disconnectCall: 'The most effective way for them to be reached is for you to disconnect from this call and dial 911 from the phone you are using.',
  assistMoveVehicle: 'In most cases they will assist in moving your vehicle to a safer location or will look out for you until help arrives.',
  callBackAndContinue: 'You can then call us back and we can continue with this dispatch to provide the service you need at that time.',
  customerDeniedPersonalSafety: 'Customer denied personal safety',

  /** Purchase Vehicle step */
  purchaseVehicle: 'Purchase Vehicle',
  tradeVehicle: 'Trade Vehicle',
  makeIsRequired: 'Make is required',
  modelIsRequired: 'Model is required',
  licensePlateIsRequired:
    'License Plate is required. Provide \'N/A\' if not applicable.',
  year: 'Year',
  yearIsRequired: 'Year is required',
  yearMustBeLessThan: 'Year must be less than ',
  yearMustBeGreaterThan: 'Year must be greater than ',
  colorIsRequired: 'Color is required',
  inoperableIsRequired: 'Inoperable is required',
  make: 'Make',
  model: 'Model',
  color: 'Color',
  licensePlate: 'License Plate',
  currentMileage: 'Current mileage',
  description: 'Description',
  inoperable: 'Inoperable',
  vehicleIsInoperable: 'Vehicle is inoperable',
  vehicleIsOperable: 'Vehicle is operable',
  operable: 'Operable',
  isTheVehicleOperable: 'Is the vehicle operable?',
  back: 'Back',
  next: 'Next',
  vehicleKeyLocationIsRequired:
    'Vehicle key location is required. Provide N/A if not applicable',
  vehicleKeysLocation: 'Vehicle key location',
  numberOfRiders: 'Number of riders',
  unattendedTow: 'Unattended tow',
  needSharedRide: 'Need shared ride?',
  numberOfPeopleSharedRide: 'Number of people who need a ride',
  pickupAddress: 'Pickup Address',
  dropoffAddress: 'Drop Off Address',
  numberOfRidersRequired: 'Must enter number of riders',

  /** Symptom Step */
  symptom: 'Symptom',
  secondaryQuestions: 'Secondary Questions',
  serviceType: 'Service type',
  symptomSelect: 'Symptom Select',
  breakDownReason: 'Break Down Reason',
  service: 'Service',
  serviceSelect: 'Service Select',

  /** Pickup Location step */
  pickupLocationIsRequired: 'Pickup location is required',
  pickupLocationType: 'Pickup location type',
  pickupLocationTypeIsRequired: 'Pickup location type is required',
  pickupLocationName: 'Pickup location name',
  preferredBranchAddresses: 'Preferred Branch Addresses',

  /** Drop Off Location step */
  dropOffLocationIsRequired: 'Drop off location is required',
  dropOffLocationType: 'Drop off location type',
  dropOffLocationTypeIsRequired: 'Drop off location type is required',
  distanceInMiles: 'Distance (in miles)',
  distanceInKilometers: 'Distance (in kms)',
  dropOffLocationName: 'Drop off location name',
  dropOffNotRequired:
    'Drop off location is not needed for the requested service  ',
  returnLocationIsSame: 'Is the vehicle pick up location the same as the vehicle return location?',
  returnLocation: 'Return Location',
  vehicleReturnLocationName: 'Vehicle return location name',

  /** TradeIn Location step*/
  tradeInDropOffLocationIsRequired: 'Trade In Drop Off Location is required',
  tradeInDropOffLocation: 'Trade In Drop Off Location',
  sameAsPickupLocation: 'Same as Pick Up Location',

  /** Review Tow Order */
  towOrderInfo: 'Tow Order Info',
  impoundOrderInfo: 'Impound Order Info',
  repoOrderInfo: 'Repo Order Info',
  orderDate: 'Order Date',
  serviceRequested: 'Service Requested',
  distance: 'Distance',
  createDispatch: 'Create Dispatch',
  notAvailable: 'Not available',
  sharedRideInformation: 'Shared Ride Information',
  sharedRideNotRequested: 'Shared ride not requested',

  /** Submit Tow Order step */
  validateDropOffLocation: 'Validate drop off location',
  validatePickupLocation: 'Validate pick up location',
  validateTradeInDropOffLocation: 'Validate trade in drop off location',
  addCustomerToDispatchSystem: 'Add customer to dispatch system',
  createTowOrderInDispatchSystem: 'Create tow order in dispatch system',
  createImpoundOrder: 'Create impound order',
  createRepoOrder: 'Create repo order',
  attachDocuments: 'Attach documents',
  sendToDispatchSystem: 'Send to Dispatch System',
  sendingDetailsToDispatchSystem: 'Sending details to Dispatch System',
  dispatchSuccessfullySubmitted: 'Dispatch successfully submitted, Call ID',
  attachAuctionHouseContactInfo: 'Attach auction house contact info',
  attachReadyLogisticsContactInfo: 'Attach ready logistics contact info',
  pickupLocationWillBeValidatedToEnableDispatch:
    'Pick up location will be validated for the details required in order to enable the dispatch.',
  pickupLocationWillBeValidatedToEnableTowOrder:
    'Pick up location will be validated for the details required in order to enable the tow order.',
  tradeInDropOffLocationWasValidated:
    'Trade in drop off location was validated in dispatch system.',
  tradeInDropOffLocationWillBeValidated:
    'Trade in drop off location will be validated for the details required in order to enable the dispatch.',
  customerAlongWithTheVehicleAndLocationsWillBeAdded:
    'Trade in drop off location will be validated for the details required in order to enable the dispatch.',
  withAllDataVerifiedTheTowOrderWillBeCreated:
    'With all data verified, the tow order will be created.',
  withAllDataVerifiedTheImpoundOrderWillBeCreated:
    'With all data verified, the impound order will be created.',
  withAllDataVerifiedTheRepoOrderWillBeCreated:
    'With all data verified, the repo order will be created.',
  allAssociatedDocumentsAreBeingUploadedAndAttachedToTheTowOrder:
    'All associated documents are being uploaded and attached to the tow order.',
  allAssociatedDocumentsAreBeingUploadedAndAttachedToTheImpoundOrder:
    'All associated documents are being uploaded and attached to the impound order.',
  allAssociatedDocumentsAreBeingUploadedAndAttachedToTheRepoOrder:
    'All associated documents are being uploaded and attached to the repo order.',
  allAuctionHouseContactInformationWillBeAttachedToTheTowOrder:
    'All auction house contact information will be attached to the tow order.',
  allReadyLogisticsContactInformationWillBeAttachedToTheTowOrder:
    'All ready logistics contact information will be attached to the tow order.',
  addingCustomerToDispatchSystem: 'Adding customer to dispatch system',
  customerWasAddedToDispatchSystem: 'Customer was added in dispatch system.',
  attachingDocumentsToTowOrder:
    'Attaching documents to tow order in dispatch system',
  attachingDocumentsToImpoundOrder: 'Attaching documents to impound order',
  attachingDocumentsToRepoOrder: 'Attaching documents to repo order',
  documentsAttachedToTowOrder: 'Documents attached to tow order.',
  documentsAttachedToImpoundOrder: 'Documents attached to impound order.',
  documentsAttachedToRepoOrder: 'Documents attached to repo order.',
  attachingAuctionHouseContactInformation:
    'Attaching auction house contact information to tow order in dispatch system.',
  auctionHouseContactInformationAttachedToTowOrder:
    'Auction house contact information attached to tow order.',
  attachingReadyLogisticsContactInformationToTowOrder:
    'Attaching ready logistics contact information to tow order in dispatch system.',
  readyLogisticsContactInformationAttachedToTowOrder:
    'Ready logistics contact information attached to tow order.',
  validatingPickupLocationInDispatchSystem:
    'Validating pick up location in dispatch system',
  validatingTradeInDropOffLocation:
    'Validating trade in drop off location in dispatch system',
  validatingDropOffLocation: 'Validating drop off location in dispatch system',
  dropOffLocationWasValidated:
    'Drop off location was validated in dispatch system.',
  creatingTowOrderInDispatchSystem: 'Creating tow order in dispatch system',
  creatingImpoundOrder: 'Creating impound order',
  creatingRepoOrder: 'Creating repo order',
  pickupLocationWasValidated:
    'Pick up location was validated in dispatch system.',
  dropOffLocationWillBeValidatedToEnableDispatch:
    'Drop off location will be validated for the details required in order to enable the dispatch.',
  dropOffLocationWillBeValidatedToEnableTowOrder:
    'Drop off location will be validated for the details required in order to enable the tow order.',
  towOrderHasBeenCompleted:
    'Tow order has been completed. Thank you for choosing NSD!',
  wasCreated: 'was created in dispatch system',
  wasCreatedInImpoundSystem: 'was created in impound system',
  wasCreatedInRepoSystem: 'was created in repo system',
  createAnother: 'Create Another',
  sendingOrderToKustomer: 'Send Tow Order to Kustomer',
  orderSuccessfullySentToKustomer: 'Tow Order successfully sent to Kustomer',
  dispatchClientPurchaseOrderId: 'Purchase Order ID',

  /* Added for Preferred locations */
  dropOffLocationInstructions: 'Drop off location instructions',
  preferredLocations: 'Preferred locations',
  approximateDistance: 'Approximate distance',
  state: 'State',
  address: 'Address',
  towDropOffAddress: 'Tow drop off address',
  timezone: 'Time zone',
  email: 'Email',
  phone: 'Phone',
  hoursOfOperation: 'Hours of operation',
  towDropWeekdayHours: 'Tow drop weekday hours',
  towDropWeekendHours: 'Tow drop weekend hours',

};
